<script setup lang="ts">
interface Props {
  name: string
  width?: string | number
  height?: string | number
}
const props = defineProps<Props>()
const icon = defineAsyncComponent(
  () => import(`../../assets/icons/${props.name}.svg?component`),
)
</script>

<template>
  <component
    :is="icon"
    v-if="icon"
    name="ICON"
    :width="width"
    :height="height"
    class="fill-current"
  />
</template>
